:root {
  --header-background: #1D1E22;
  --header-font-color: #FFFFFF;
  --footer-background: #1D1E22;
  --footer-font-color: #FFFFFF;
  --section-font-color: #FFFFFF;
  --section-desc-background: rgba(0, 0, 0, 0.6);
  --link-color: rgb(14, 137, 182);
  --link-color-hover: #FFFFFF;
}

a {
  color: var(--link-color);
  text-decoration: none;
}

a:hover {
  color: var(--link-color-hover);
}

.App {
  font-size: 24px;
}

header {
  height: 80px;
  background: var(--header-background);
  color: var(--header-font-color);
  display: flex;
  align-items: center;
  padding: 0 20px;
  gap: 30px;
}

header #wordmark {
  font-size: 40px;
  font-family: 'Bitsumishi', sans-serif;
  display: flex;
  align-items: center;
  gap: 20px;
}

header #wordmark img#logo {
  width: 40px;
  height: 31px;
}

.content-wrapper {
  max-width: 1200px;
  margin: 0 auto;
}

section.main {
  padding: 150px 0;
}

section.main .intro {
  font-size: 30px;
}

section.main h1 {
  font-size: 60px;
  font-family: 'Bitsumishi', sans-serif;
  margin-bottom: 0px;
}

.site-section {
  padding: 100px 0;
  background: #aeaeae;
}

.site-section .content-wrapper {
  display: flex;
  flex-direction: row;
  gap: 100px;
  align-items: center;
}

.site-section:nth-child(even) .content-wrapper {
  flex-direction: row-reverse;
}

.site-section .logo img {
  max-width: 320px;
}

.site-section .description {
  padding: 30px;
  background: var(--section-desc-background);
  border-radius: 10px;
  flex-shrink: 1;
  color: var(--section-font-color);
}

.site-section .description h2 {
  margin: 0 0 10px;
}

.site-section.site-outofcards {
  background: url('outofcards-bg.jpg');
}

.site-section.site-outofgames {
  background: url('outofgames-bg.jpg');
  background: #0a384d;
  background-size: cover;
}

footer {
  text-align: center;
  background: var(--footer-background);
  color: var(--footer-font-color);
  padding: 30px;
  flex-shrink: 0;
  font-size: 16px;
}